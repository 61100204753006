import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Willkommen bei den Softwareentwicklern ihres Vertrauens" />
      <h1>Warum palmomedia?</h1>
      <p>
        Weil wir es wirklich ernst meinen! Unser Aufrag ist Ihr Produkt. Wir
        sind nicht daran interessiert Ihnen möglichst viel Technologie zu
        verkaufen. Wir wollen mit ihnen zusammen erfolgreich sein.
      </p>
      <h2>Webseiten</h2>
      <p>
        Wir modernisieren (Refactoring) oder erstellen Ihre Webseite. <br />
        Sehen Sie sich dazu unseren{" "}
        <a
          href="https://mitchobrian.medium.com/eine-webseite-drei-frameworks-jamstack-3da43a2b9bb5"
          target="_blank"
          rel="noreferrer"
        >
          Drei Frameworks Vergleich
        </a>{" "}
        an!
      </p>
      <h2>Beratung</h2>
      <p>
        Sie haben ein Webprojekt am Start und benötigen Beratung? <br />
        Wir unterstützen bei der Planung und Umsetzung.
      </p>
      <h2>DevOps</h2>
      <p>
        Wir verstehen uns als Entwickler und Betreiber - also echte Developers
        and Operations. Wir machen es Möglich.
      </p>
      <hr />
      <br />
      <p>
        Wir verstehen uns als Möglichmacher im Full-Service Segment. Egal ob
        legacy Software oder Grünewiese, wir bauen Ihnen Ihre Webapplikation und
        helfen beim Hosten.
      </p>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
